<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<mat-menu #navMenu="matMenu">
    <a mat-menu-item [routerLink]="'/settings'">Account Settings</a>
    <a mat-menu-item (click)="switchAccount()">
        {{isOrganizationAccount ? 'Switch to Personal Account' : 'Switch to Organization Account'}}</a>
    <a mat-menu-item [routerLink]="'/my-library'">My Library</a>
    <a mat-menu-item [routerLink]="'/autofill-manager'" *ngIf="isOrganizationAccount">Autofill Manager</a>
    <a mat-menu-item (click)="logOut()">Logout</a>
</mat-menu>
<!-- Navigation (Sidebar)-->
<ng-container *ngIf="isScreenSmall && !isPaymentPage">
    <fuse-vertical-navigation class="w-full min-w-full sm:min-w-100 sm:max-w-100  print:hidden mt-0" [mode]="'over'"
        [name]="'mainNavigation'" [navigation]="navigation" #verticalNavigation [opened]="false"
        style="background-color: #5040dd !important;color:white;">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div style="margin-bottom:20px;" [style.background-color]="user == null ? '#5040dd' : '#322889'">
                <div class="flex justify-between pl-4 mb-1 p-0" style="min-height:fit-content !important">
                    <div class="flex ml-8 w-full justify-end">
                        <img src="./assets/images/close_icon.svg" (click)="close()" alt="Close menu"
                            style="width:17px;height:17px;object-fit: contain;margin:20px 20px 0px 0px">
                    </div>
                </div>
                <ng-container *ngIf="user != null">
                    <div class="min-h-10 h-auto px-4 pt-0 pb-4 flex items-center">
                        <div style="width:40px;height:40px;border-radius:100%;border:1px solid white;margin-left:12px;flex-shrink:0">
                            <img [src]="isOrganizationAccount ? organization?.profile_image : user?.profile_image"
                                onerror="this.src = './assets/images/profile-icon.png'"
                                class="w-full h-full contain rounded-full object-cover" alt="Profile image"
                                style="border:1px solid #b4b4b4;">
                        </div>
                        <button [matMenuTriggerFor]="navMenu" style="margin-left:12px;"
                            class="flex items-center gap-6 notranslate">
                            <p class="text-white text-lg ml-0 text-left truncate max-w-[200px]" style="margin-left:0px !important;font-weight:normal">
                                {{isOrganizationAccount ? organization?.name : user?.name}}
                            </p>
                            <span><img src="./assets/images/down_arrow.svg" alt="Arrow mark"
                                    style="width:10px;height:10px;object-fit:contain;"></span>
                        </button>
                        <!-- <mat-accordion>
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                            style="background-color: transparent;box-shadow: none !important;">
                            <mat-expansion-panel-header style="padding-left:17px;">
                                <mat-panel-title>
                                    <p class="text-white text-lg ml-0"
                                        style="margin-left:0px !important;font-weight:normal">john
                                        samuel</p>
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            
                        </mat-expansion-panel>
                    </mat-accordion> -->

                    </div>
                </ng-container>
                <div class="flex justify-between mt-0 mb-4 pt-4 pb-8 gap-2" style="padding:0rem 2rem;">
                    <button class="block btn_red" mat-flat-button [routerLink]="['/','pricing']"
                        style="align-self: flex-start;" *ngIf="user != null">
                        Upgrade My Plan</button>
                    <div class="bg-white pl-4 pr-4 pt-2 pb-2 cursor-pointer" style="color:#312889;border-radius:8px;"
                        (click)="openLoginModal()" *ngIf="user == null">
                        Login
                    </div>
                    <a class="flex gap-2 items-center" href="tel:800 282 3122">
                        <img src="./assets/images/phone_white.svg" style="width:20px;height:20px;object-fit:contain"
                            alt="Phone icon"><span class="text-white"
                            style="font-family: 'Poppins', sans-serif;font-weight:500;margin-left:0px !important">Call
                            Now</span>
                    </a>
                </div>


            </div>
        </ng-container>
        <ng-container class="flex" fuseVerticalNavigationContentFooter *ngIf="user != null">
            <!-- <div class="flex m-auto">
                <button class="block w-full sm:w-auto mt-6"
                    style="margin-left:1.5rem;margin-right:1.5rem;color:white;background-color:#dd0d00;padding:0.7rem 1.5rem;align-self: flex-start;border-radius: 8px;line-height: normal;font-weight:400;font-size:13px;">Upgrade
                    My Plan</button>
            </div> -->
            <!-- <div class="flex gap-4 mt-8 mb-8 items-center" style="margin-left:1.7rem;margin-right:1.5rem;">
                <img src="./assets/temp/logout_icon.svg" style="width:20px;height:20px;object-fit: contain;">
                <p class="text-white">Logout</p>
            </div> -->
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-white">

    <!-- Header -->
    <div
        class="sticky bg-white top-0 flex flex-col flex-0 pb-0 sm:pb-0 justify-center w-full h-auto overflow-hidden z-49 shadow-none dark:shadow-none print:hidden">
        <!-- Top bar -->
        <div class="relative light flex flex-auto justify-center w-full px-4 md:px-8 bg-white pt-2 pb-2">
            <div class="flex items-center w-full max-w-360 m-auto gap-3 sm:gap-2 lg:gap-0 justify-between"
                style="max-width:90% !important;">
                <!-- Logo -->
                <div class="flex flex-row gap-2">
                    <!-- Navigation toggle button -->
                    <ng-container *ngIf="isScreenSmall && !isPaymentPage">
                        <button (click)="this.changeNavigationStatus(true)">
                            <!-- <mat-icon [svgIcon]="'heroicons_outline:bars-3-bottom-left'"></mat-icon> -->
                            <img src="./assets/images/hamburger.svg" alt="Menu">
                        </button>
                    </ng-container>
                    <ng-container>
                        <div class="flex items-center">
                            <a [routerLink]="'/home'"><img class="w-auto h-[40px] md:h-[4.6rem]" alt="PWA Logo"
                                    src="assets/images/header-logo.gif" alt="Logo image" style="object-fit: contain;"></a>
                            <!-- <span class="text-black ml-3">
            <p style="font-size: 12px;font-weight:bold;line-height: 1.2;font-family:DomaineDisplayNarrow;">America’s Best<br>
                Marketing Superstore</p>
            <p class="mt-0.5" style="font-size: 9px;font-weight:400;">More Options, Better Solutions</p>
        </span> -->
                        </div>
                    </ng-container>
                </div>

                <!-- Components -->
                <div class="flex items-center pl-0 sm:pl-0 justify-end  gap-1 space-x-0.5 sm:space-x-1 navbar_width"
                    *ngIf="!isPaymentPage">
                    <languages></languages>
                    <!--   <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                    
                    <shortcuts></shortcuts>
                    <messages></messages> -->
                    <div class="flex items-center gap-2">

                        <button mat-flat-button class="w-auto hidden md:block sm:w-auto mr-2 m-auto btn_red shrink-0"
                            *ngIf="user != null" [routerLink]="['/','pricing']"
                            style="align-self: flex-start;margin-left:10px !important;padding:0.1rem 0.9rem !important;font-size:11px !important;min-height: auto !important;">Upgrade
                            My Plan</button>
                        <a href="tel:8002823122" class="items-center gap-2 hidden md:flex shrink-0"
                            style="margin-left:15px !important;margin-right:0px !important">
                            <img class="hidden md:flex" src="./assets/images/phone_icon.svg"
                                style="width:22px;height:22px;object-fit:contain" alt="Phone icon">
                            <p class="text-black text-md hidden lg:block "
                                style="font-family: 'Poppins', sans-serif;font-weight:500;margin-left:0px !important">
                                800
                                282 3122</p>
                        </a>
                        <div class="hidden md:flex"
                            style="border-right:1px solid #afafaf;height: 30px;margin-left:14px !important;margin-right:14px !important;">
                        </div>
                        <button mat-button [matMenuTriggerFor]="navMenu" class="hidden md:flex notranslate"
                            style="margin-left:0px !important" *ngIf="user != null">
                            <div class="flex flex-nowrap flex-row items-center shrink-0">
                                <img style="width:28px;height:28px;margin-right:12px !important;object-fit: cover;border:1px solid #b4b4b4"
                                    [src]="isOrganizationAccount ? organization?.profile_image : user?.profile_image"
                                    onerror="this.src = './assets/images/profile-icon.png'" alt="Profile image"
                                    class=" contain rounded-full">

                                <div class="text-black text-sm ml-0 truncate md:max-w-[100px] xl:max-w-[200px]"
                                    style="font-family: poppins;font-weight:normal;font-size:14px !important;">
                                    {{isOrganizationAccount ? organization?.name : user?.name}}
                                </div>
                                <img src="./assets/images/down_arrowmark_black.svg" alt="Arrow mark"
                                    style="width:10px;height: 10px;object-fit: contains;margin-left:10px;">
                            </div>

                        </button>
                        <div *ngIf="user != null" class="hidden md:flex ml-[14px] mr-[14px]"
                            style="border-right:1px solid #afafaf;height: 30px;">
                        </div>
                        <div class="flex md_navbar items-center">
                            <!-- <a class="gift_icon" style="margin-left:12px"><img class="navicon_sm"
                                    src="./assets/temp/gift_icon.svg"
                                    style="width:20px;height:20px;object-fit: contain;"></a> -->
                            <search class="hidden sm:flex mb-0" [appearance]="'bar'" [debounce]="300" [minLength]="3">
                            </search>
                            <schedule-demo *ngIf="isScreenSmall"></schedule-demo>
                            <referral *ngIf="user != null"></referral>
                            <notifications *ngIf="user != null"></notifications>
                            <button mat-icon-button [routerLink]="'/cart'" #cartButton *ngIf="user != null"
                                class="notranslate">
                                <ng-container *ngIf="cartCount > 0">
                                    <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
                                        <span
                                            class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-[#5040dd] text-white text-xs font-medium">
                                            {{cartCount}}
                                        </span>
                                    </span>
                                </ng-container>
                                <mat-icon [svgIcon]="'nav_cart_icon'" style="padding: 3px;"
                                    class="text-black"></mat-icon>
                            </button>
                            <a style="cursor: pointer;" (click)="openLoginModal()" *ngIf="user == null"
                                class="ml-2">Login</a>
                            <div class="hidden lg:hidden">
                                <button class="langauage_selection" mat-flat-button [matMenuTriggerFor]="language"
                                    style="padding:0.4rem 1rem;background-color:#e7f0f2;border-radius:8px !important;">
                                    <div class="flex flex-nowrap flex-row items-center">
                                        <p class="text-black" style="font-size: 12px;;">English</p>
                                        <mat-icon svgIcon="mat_outline:keyboard_arrow_down"></mat-icon>
                                    </div>
                                </button>
                                <mat-menu #language="matMenu">
                                    <button mat-menu-item>Spanish</button>
                                    <button mat-menu-item>German</button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>

                    <!-- <button
                        class="lg:hidden"
                        mat-icon-button
                        (click)="quickChat.toggle()">
                        <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
                    </button> -->

                </div>
            </div>
        </div>
        <search class="px-8 mb-4 mt-2 w-full flex sm:hidden" *ngIf="!isPaymentPage" [appearance]="'basic'"
            [debounce]="300" [minLength]="3">
        </search>
        <ng-container *ngIf="!isScreenSmall && !isPaymentPage">
            <div class="flex flex-auto justify-center px-4 md:px-8 dark:bg-gray-700 bg-[#5040dd]">
                <div class="relative flex items-center w-full max-w-360 h-14 justify-between"
                    style="max-width:90% !important;">
                    <fuse-horizontal-navigation [name]="'hNavigation'"
                        [navigation]="navigation"></fuse-horizontal-navigation>

                    <schedule-demo [appearance]="'button'"></schedule-demo>
                </div>
            </div>
        </ng-container>
        <!-- <div class="block sm:hidden bg-white">
            <search [appearance]="'basic'" [debounce]="300" [minLength]="3"
                style="width:90%;padding-left:1rem;padding-right:1rem;margin: auto;"></search>
        </div> -->
        <!-- Mega Menu -->
        <!-- *ngIf="!isScreenSmall && !isPaymentPage" -->
        <ng-container>
            <div class="hidden flex-auto justify-center pl-4 pr-4 md:pr-8 md:pl-4 bg-card"
                style="background-color: #5040dd !important;">
                <div class="relative flex items-center w-full max-w-360 h-auto m-auto justify-between"
                    style="max-width: 90% !important;">
                    <!-- <fuse-horizontal-navigation class="-mx-4" [name]="'mainNavigation'" [navigation]="navigation">

                    </fuse-horizontal-navigation> -->
                    <div class="navbar_sample">
                        <div class="dropdown_sample">
                            <button class="dropbtn flex gap-1 items-center mt-2 mb-2">Print Products
                                <!-- <mat-icon svgIcon="mat_outline:keyboard_arrow_down" style="color:white;"></mat-icon> -->
                                <img src="./assets/images/down_arrow.svg" alt="Arrow mark"
                                    style="width:10px;height:10px;object-fit:contain;">
                            </button>
                            <div class="dropdown-content_1">
                                <!-- <div class="header">
                              <h2>Mega Menu</h2>
                            </div>    -->
                                <div class="flex menu_row pl-4 pr-6 pt-4 pb-4">
                                    <div class="grid grid-rows-6 gap-4 grid-flow-col column_menu">
                                        <div *ngFor="let category of configuration?.print_categories">
                                            <a class="cursor-pointer p-0" [routerLink]="['/','category', category.slug]"
                                                [ngClass]="{'active' : currentUrl == '/category/'+category.slug}">
                                                <h2 style="font-weight: normal;">{{category.title}}</h2>
                                            </a>
                                            <!-- <div class="grid grid-cols-1 gap-0">
                                                <ng-container
                                                    *ngFor="let subCategory of category?.sub_categories?.slice(0,6); let i= index; let last = last">
                                                    <ng-container *ngIf="i != 5 || !last">
                                                        <a [ngClass]="{'active' : currentUrl == '/search?category='+category.slug+'&sub_category='+subcategory.slug}"
                                                            [routerLink]="'/search'"
                                                            [queryParams]="{'category': category.slug,'sub_category': subcategory.slug}">
                                                            {{subCategory.title}}
                                                        </a>
                                                    </ng-container>
                                                    <ng-container *ngIf="i == 5 && last">
                                                        <a [ngClass]="{'active' : currentUrl == '/search?category='+category.slug+'&sub_category='+subcategory.slug}"
                                                            class="underline"
                                                            [routerLink]="['/','category', category.slug]">
                                                            See more
                                                        </a>
                                                    </ng-container>
                                                </ng-container>
                                            </div> -->
                                        </div>
                                    </div>

                                    <div class="column_menu pr-[5px] flex flex-grow justify-end">
                                        <img src="./assets/images/product3.png" alt="Print products promotional image"
                                            style="object-fit: contain;width:auto;height:200px;">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dropdown_sample">
                            <button class="dropbtn flex gap-1 items-center mt-2 mb-2">Digital Products
                                <!-- <mat-icon svgIcon="mat_outline:keyboard_arrow_down" style="color:white;width:12px !important;height:12px !important;"></mat-icon> -->
                                <img src="./assets/images/down_arrow.svg" alt="Arrow mark"
                                    style="width:10px;height:10px;object-fit:contain;">
                            </button>
                            <div class="dropdown-content_1">
                                <div class="flex menu_row pl-4 pr-6 pt-4 pb-4">
                                    <div class="grid grid-rows-6 grid-flow-col column_menu">
                                        <div *ngFor="let category of configuration?.digital_categories?.slice(0,3)">
                                            <a class="cursor-pointer p-0" [routerLink]="['/','category', category.slug]"
                                                [ngClass]="{'active' : currentUrl == '/category/'+category.slug}">
                                                <h2 style="font-weight: normal;">{{category.title}}</h2>
                                            </a>
                                            <!-- <div class="grid grid-cols-1 gap-0">
                                                <ng-container
                                                    *ngFor="let subCategory of category?.sub_categories?.slice(0,6); let i= index; let last = last">
                                                    <ng-container *ngIf="i != 5 || !last">
                                                        <a [ngClass]="{'active' : currentUrl == '/search?category='+category.slug+'&sub_category='+subcategory.slug}"
                                                            [routerLink]="'/search'"
                                                            [queryParams]="{'category': category.slug,'sub_category': subcategory.slug}">
                                                            {{subCategory.title}}
                                                        </a>
                                                    </ng-container>
                                                    <ng-container *ngIf="i == 5 && last">
                                                        <a [ngClass]="{'active' : currentUrl == '/search?category='+category.slug+'&sub_category='+subcategory.slug}"
                                                            class="underline"
                                                            [routerLink]="['/','category', category.slug]">
                                                            See more
                                                        </a>
                                                    </ng-container>
                                                </ng-container>

                                            </div> -->
                                        </div>

                                        <a href="" (click)="onClickVID(false)" class="text-black cursor-pointer">
                                            Virtual Biz Cards
                                        </a>
                                        <a href="" (click)="onClickVID(true)" class="text-black cursor-pointer">
                                            Email Signatures
                                        </a>
                                        <a href="https://crmlevel10.com" target="_blank" class="text-black">
                                            CRM Level X
                                        </a>


                                        <!-- <div>
                                            <h1>Digital Services</h1>
                                            <div class="grid grid-cols-1 gap-0">
                                                
                                                <a (click)="onClickVID(false)" class="text-black cursor-pointer">
                                                    Virtual Biz Cards
                                                </a>
                                                <a (click)="onClickVID(true)" class="text-black cursor-pointer">
                                                    Email Signatures
                                                </a>
                                                <a href="https://crmlevel10.com" target="_blank" class="text-black">
                                                    CRM Level X
                                                </a>
                                            </div>
                                        </div> -->
                                    </div>

                                    <div class="column_menu pr-[5px] flex flex-grow justify-end">
                                        <img src="./assets/images/product3.png" alt="Digital products promotional image"
                                            style="object-fit: contain;width:auto;height:200px;">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dropdown_sample" *ngIf="user != null">
                            <a class="dropbtn flex items-center mt-2 mb-2" [routerLink]="['/','social-media','posts']"
                                [ngClass]="{'active' : currentUrl == '/social-media/posts'}">Social
                                Media
                                <i class="fa fa-caret-down"></i>
                            </a>
                        </div>
                        <!-- Pricing -->
                        <div class="dropdown_sample">
                            <a class="dropbtn flex items-center mt-2 mb-2" [routerLink]="['/','pricing']"
                                [ngClass]="{'active' : currentUrl == '/pricing'}">Pricing
                                <i class="fa fa-caret-down"></i>
                            </a>
                        </div>
                        <!-- About Us -->
                        <div class="dropdown_sample">
                            <a class="dropbtn flex items-center mt-2 mb-2" [routerLink]="['/','about-us']"
                                [ngClass]="{'active' : currentUrl == '/about-us'}">About Us
                                <i class="fa fa-caret-down"></i>
                            </a>
                        </div>
                        <!-- Help -->
                        <div class="dropdown_sample">
                            <button class="dropbtn flex gap-1 items-center mt-2 mb-2">Help
                                <!-- <mat-icon svgIcon="mat_outline:keyboard_arrow_down" style="color:white;"></mat-icon> -->
                                <img src="./assets/images/down_arrow.svg" alt="Arrow mark"
                                    style="width:10px;height:10px;object-fit:contain;">
                            </button>
                            <div class="dropdown-content_1">
                                <div class="row menu_row pl-4 pr-4 pt-4 pb-4">
                                    <div class="column_menu">
                                        <a [routerLink]="['/','contact-us']"
                                            [ngClass]="{'active' : currentUrl == '/contact-us'}">Contact Us</a>
                                        <a [routerLink]="['help','faq']"
                                            [ngClass]="{'active' : currentUrl == '/help/faq'}">FAQ</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <schedule-demo [appearance]="'button'" class="mt-2 mb-2"></schedule-demo>
                    <!-- Language -->
                    <button [matMenuTriggerFor]="language" mat-flat-button class="hidden"
                        style="padding:0.5rem 0.5rem 0.5rem 1rem;background-color:#2716bc;border-radius:8px !important;">
                        <div class="flex flex-nowrap flex-row items-center">
                            <p class="text-white" style="font-size: 13px;;">English</p>
                            <mat-icon svgIcon="mat_outline:keyboard_arrow_down"
                                style="color:white !important;margin-left:12px;"></mat-icon>
                        </div>
                    </button>
                    <mat-menu #language="matMenu">
                        <button mat-menu-item>Spanish</button>
                        <button mat-menu-item>German</button>
                    </mat-menu>
                    <!-- Language - End -->
                </div>
            </div>
        </ng-container>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full">
        <div class="flex flex-col flex-auto w-full sm:overflow-hidden bg-white">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>


    <!-- footer -->
    <div class="w-full sm:flex flex sm:flex-column md:flex-row pt-0 text-white" style="background-color:#24213e"
        *ngIf="!isPaymentPage">
        <div class="px-4 md:px-8 pt-12 pb-12 m-auto md:flex justify-between" style="max-width:90%;flex-grow: 1;">
            <div class="hidden sm:justify-between md:justify-start sm:grid sm:grid-cols-4 footer_contents"
                style="gap:50px">
                <div>
                    <ul class="footer_contents">
                        <li><a [routerLink]="'/'" [ngClass]="{'active' : currentUrl == '/home'}">Home</a></li>
                        <li><a [routerLink]="'/social-media/posts'"
                                [ngClass]="{'active' : currentUrl == '/social-media/posts'}">Social Media</a></li>
                        <li><a [routerLink]="'/pricing'" [ngClass]="{'active' : currentUrl == '/pricing'}">Pricing</a>
                        </li>
                        <li><a [routerLink]="'/about-us'" [ngClass]="{'active' : currentUrl == '/about-us'}">About
                                Us</a></li>
                        <li><a [routerLink]="'/help/faq'" [ngClass]="{'active' : currentUrl == '/help/faq'}">Help</a>
                        </li>
                        <li><a [routerLink]="'/contact-us'" [ngClass]="{'active' : currentUrl == '/contact-us'}">Contact
                                Us</a></li>
                        <li><a [routerLink]="'/privacy-policy'"
                                [ngClass]="{'active' : currentUrl == '/privacy-policy'}">Privacy Policy</a></li>
                        <li><a [routerLink]="'/terms-conditions'"
                                [ngClass]="{'active' : currentUrl == '/terms-conditions'}">Terms & Conditions</a></li>
                        <li><a [routerLink]="'/ccpa'" [ngClass]="{'active' : currentUrl == '/ccpa'}">CCPA</a></li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li style="font-weight:500 !important;">Print Products</li>
                        <ng-container *ngFor="let category of configuration?.print_categories">
                            <li>
                                <h2><a [routerLink]="['/','category', category.slug]"
                                        [ngClass]="{'active' : currentUrl == '/category/'+category.slug}">{{category.title}}</a>
                                </h2>
                            </li>
                        </ng-container>

                    </ul>
                </div>
                <div>
                    <ul>
                        <li style="font-weight:500 !important;">Digital Products</li>
                        <ng-container *ngFor="let category of configuration?.digital_categories">
                            <li>
                                <h2><a [routerLink]="['/','category', category.slug]"
                                        [ngClass]="{'active' : currentUrl == '/category/'+category.slug}">{{category.title}}</a>
                                </h2>
                            </li>
                        </ng-container>
                        <li>
                            <h2><a href="https://blog.printingwarehouses.com" target="_blank">Blog</a>
                            </h2>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Neswletter Subscription -->
            <div class="text-left mt-0 sm:mt-12 md:mt-0">
                <h2 class="title_sm max-w-[270px]"
                    style="font-family: 'Poppins', sans-serif;font-size:27px;font-weight:600;color:white;line-height: 1.1;">
                    Subscribe Our Newsletter </h2>
                <form class="ml-auto" [formGroup]="newsletterForm" #newsletterNgForm="ngForm">
                    <div class="mb-0 mt-3">

                        <mat-form-field class="col-span-1 md:col-span-2 w-full sm:w-[300px]"
                            [hideRequiredMarker]="hideRequiredMarker">
                            <mat-label class="text-white text-xs">Email Address</mat-label>
                            <input id="email" matInput type="email" [formControlName]="'email'"
                                [placeholder]="'Enter Your Email Address'" class="text-black">
                            <mat-error *ngIf="newsletterForm.get('email').hasError('required')">
                                Email shouldn't be empty
                            </mat-error>
                            <mat-error *ngIf="newsletterForm.get('email').hasError('email')">
                                Please provide a valid email id
                            </mat-error>
                        </mat-form-field>

                        <!-- <label for="email" class="block mb-1 text-xs font-xs  text-white" style="font-weight:200;">Email
                            Address</label>
                        <input type="email" id="newsLetterEmail" [placeholder]="'Enter Your Email Address'"
                            class="w-full sm:w-[300px] bg-white border-0  text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block pt-1.5 pb-1.5 pl-4 pr-4  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required style="width:300px"> -->
                    </div>
                </form>
                <button mat-flat-button class="block w-full sm:w-auto mt-0 btn_normal" (click)="subscribeNewsletter()"
                    style="padding:0.6rem 3.3rem !important;align-self: flex-start !important;">Subscribe</button>
                <div class="flex flex-row gap-3 mt-6 justify-center sm:justify-start">
                    <a href="https://www.facebook.com/printingwarehouses/"><img src="./assets/images/fb_icon.svg"
                            class="w-6 h-6 contain" alt="Facebook"></a>
                    <a href="https://www.instagram.com/printingwarehouses/"><img src="./assets/images/insta_icon.svg"
                            class="w-6 h-6 contain" alt="Instagram"></a>
                    <a href="https://twitter.com/printingwa"><img src="./assets/images/twitter_footer_icon.svg"
                            class="w-6 h-6 contain" alt="Twitter"></a>
                    <a href="https://www.linkedin.com/company/printingwarehouses/"><img
                            src="./assets/images/linkedinFooter_icon.svg" class="w-6 h-6 contain" alt="Linkedin"></a>

                </div>
                <img src="assets/images/footer-animation.gif" alt="Logo Animation" title="Logo Animation" style="width:300px;margin-top:20px;height:auto">
            </div>
        </div>
    </div>
    <div class="w-full" style="background-color: #605989;height:1px"></div>
    <div class="w-full" style="background-color: #24213e;">
        <div class="flex gap-2 sm:gap-12 pt-6 pb-8  px-0 md:px-8  items-center m-auto" style="max-width:90%">
            <div>
                <img class="logo_footer w-[100px] h-[100px] object-contain" src="./assets/images/footer_logo@3x.png"
                    alt="PWA Logo">
            </div>
            <div>
                <p class="text-white footer_text" style="font-size:10px;font-weight:300;opacity: 0.8;">
                    © {{currentYear}} All Rights Reserved, Printing Warehouses of America</p>
                <p class="text-white flex mt-2 footer_text" style="font-size:10px;font-weight:300;opacity: 0.8;">Made
                    with
                    <span class="ml-1 mr-1"><img src="./assets/images/heart.png" alt="Heart icon"
                            style="width:15px;height:15px;object-fit: contain;"></span>
                    by QBLER
                </p>
            </div>
        </div>
    </div>
    <!-- / -->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>