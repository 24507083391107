import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { type CarouselItem, type CarouselOptions, type CarouselInterface, Carousel, initFlowbite } from "flowbite";
import { HomeService } from './home.service';
import { Subject, takeUntil } from 'rxjs';
import { EmailSignatureResponse, FeaturedCategory, FeaturedTemplate, HomeBanner } from './home.types';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginComponent } from 'app/shared/login/signin.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { VID_URL } from 'environments/environment';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { UserService } from 'app/core/user/user.service';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { BusinessCategories } from './business-categories/business-categories.component';
import { User } from 'app/core/user/user.types';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { HireDesignerPopup } from '../product-detail/hire-designer/hire-designer.component';

// @Component({
//     selector: 'home',
//     standalone: true,
//     templateUrl: './home.component.html',
//     encapsulation: ViewEncapsulation.None,
//     imports: [CommonModule]
// })
@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true,
    imports: [CommonModule, TranslocoModule, NgOptimizedImage, MatTooltipModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatInputModule, RouterModule]
})

export class HomeComponent implements OnInit {
    @ViewChild('viewWidth')
    viewWidth: ElementRef;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('ebookNgForm') ebookNgForm: NgForm;
    ebookForm: UntypedFormGroup;
    isOrganizationAccount = false
    hideRequiredMarker = "true"
    emailSignatures: EmailSignatureResponse
    featuredTemplates: FeaturedTemplate[]
    featuredCategories: FeaturedCategory[]
    vidCards = [
        {
            image: './assets/images/vid_preview1.png', name: "V BIZ CARD 1001", id: 1001, altText: "Modern Virtual Business Cards"
        },
        {
            image: './assets/images/vid_preview2.png', name: "V BIZ CARD 1002", id: 1002, altText: "Classic Virtual Business Cards"
        },
        {
            image: './assets/images/vid-preview3.png', name: "V BIZ CARD 1003", id: 1003, altText: "Professional Virtual Business Cards"
        },
        {
            image: './assets/images/vid_preview4.png', name: "V BIZ CARD 1004", id: 1004, altText: "Creative Virtual Business Cards"
        },
        {
            image: './assets/images/vid_preview5.png', name: "V BIZ CARD 1005", id: 1005, altText: "Elegant Virtual Business Cards"
        },
        {
            image: './assets/images/vid_preview6.png', name: "V BIZ CARD 1006", id: 1006, altText: "Unique Virtual business cards"
        },
        {
            image: './assets/images/vid_preview7.png', name: "V BIZ CARD 1007", id: 1007, altText: "Sleek Virtual business cards"
        },
        {
            image: './assets/images/vid_preview8.png', name: "V BIZ CARD 1008", id: 1008, altText: "Stylish Virtual Business Cards"
        }
    ];
    date = new Date()
    ngOnInit(): void {
        this.isOrganizationAccount = this._localStorageService.accountType == 'organization'
        this._homeService.banners$
            .pipe(takeUntil(this._unsubscribeAll)).subscribe((banners: HomeBanner[]) => {
                this.banners = [banners[0]]

                // setTimeout(() => {
                //     this.configureBanners()
                // }, 300);

            })

        this.ebookForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        });

        this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
            this.isOrganizationAccount = this._localStorageService.accountType == 'organization'
        })


    }
    observer: ResizeObserver;


    // For horizontal scrolls in Home page
    difference
    innerDifference
    ngAfterViewInit(): void {
        this.observer = new ResizeObserver(_ => {
            const element = this.viewWidth.nativeElement;
            this.difference = -(window.innerWidth - element.offsetWidth) / 2
            this.innerDifference = (window.innerWidth - element.offsetWidth) / 2
        });

        this.observer.observe(this.viewWidth.nativeElement);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.observer.unobserve(this.viewWidth.nativeElement);
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    onClickBanner(banner) {
        switch (banner.url) {
            case "openCreateDesignModal": {
                if (this.checkLoginStatus())
                    this.openCreateDesignModal()
                break;
            }
            case "openVID": {
                if (this.checkLoginStatus())
                    this.openVID(null, null)
                break;
            }
            default: {
                this._router.navigateByUrl(banner.url)
            }
        }
    }

    openCreateDesignModal() {

    }

    downloadEbook() {
        this.ebookForm.markAllAsTouched();
        if (this.ebookForm.invalid) {
            return;
        }
        this._spinner.show()
        let request = this._homeService.downloadEBook(this.ebookForm.value)
        request.subscribe({
            next: (response: any) => {
                this._spinner.hide()
                this.ebookForm.enable()
                this._fuseConfirmation.open({
                    title: "Success",
                    message: response.message ? response.message : "Thank you for subscribing to our newsletter",
                    dismissible: false,
                    actions: {
                        cancel: { show: false },
                        confirm: { label: "Ok" }
                    },
                    icon: { show: false }
                });
            },
            error: error => {
                this._spinner.hide()
                this.ebookForm.enable()
                this._toast.error(error ? error.error.message : "Something went wrong. Please try again")
            }
        })
    }

    openVID(isEmailSignature, id) {
        if (!this.checkLoginStatus())
            return
        this._spinner.show()
        this._userService.getVIDProfiles().subscribe({
            next: (res) => {
                this._spinner.hide()
                sessionStorage.removeItem('isFromVid')
                if (isEmailSignature == null)
                    window.location.href = VID_URL
                else if (isEmailSignature == true)
                    window.open(VID_URL + 'email-signatures')
                else
                    window.open(VID_URL + 'products/' + id)
            },
            error: (err) => {
                this._spinner.hide();
                const dialogRef = this._confirmationService.open({
                    title: "Sorry",
                    message: err ? err.error.message : `Something went wrong. Please try again.`,
                    dismissible: false,
                    actions: {
                        confirm: { label: "Ok" }
                    }
                });
                dialogRef.afterClosed().subscribe((result) => {
                    if (result == 'confirmed')
                        if (err.error.url != null) {
                            window.location = err.error.url
                        }
                });
            }
        })
    }

    checkLoginStatus() {
        if (this._localStorage.accessToken?.length > 0)
            return true
        else
            this.openLoginModal()
    }

    openLoginModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { checklistId: 0 };
        this._matDialog.open(LoginComponent, dialogConfig)
            .afterClosed().subscribe(value => {
                if (value != null && value.length > 1) {

                }
            });
    }

    openAndMorePopup() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = ['w-full', 'max-w-200']
        this._matDialog.open(BusinessCategories, dialogConfig)
    }

    openAutofillManager() {
        if (!this.checkLoginStatus())
            return
        if (this.isOrganizationAccount) {
            this._router.navigate(['/', 'autofill-manager'])
        }
        else {
            this._fuseConfirmation.open({
                title: "Switch Account",
                message: "You need to switch to the organization account to access the autofill manager. Would you like to proceed?",
                dismissible: false,
                actions: {
                    confirm: { label: "Continue" }
                },
                icon: { show: false }
            }).afterClosed().subscribe(result => {
                if (result == 'confirmed') {
                    this.broadcast.broadcast('action-switch-to-organization', { redirect: ['/', 'autofill-manager'] })

                }
            })

        }
    }
    openDesignerRequirementModal() {
        const dialogConfig = new MatDialogConfig();
        this._matDialog.open(HireDesignerPopup, dialogConfig)
    }
    /**
     * Constructor
     */
    constructor(private _homeService: HomeService, private _router: Router, private _localStorage: LocalStorageService,
        private _matDialog: MatDialog, private _spinner: NgxSpinnerService, private _confirmationService: FuseConfirmationService,
        private _userService: UserService, private fb: UntypedFormBuilder, private _toast: ToastrService, private _localStorageService: LocalStorageService,
        private _fuseConfirmation: FuseConfirmationService, private _route: ActivatedRoute, private _changeDetectorRef: ChangeDetectorRef,
        private broadcast: BroadcastService) {
        this.emailSignatures = this._route.snapshot.data['data'][1]
        this.featuredTemplates = this._route.snapshot.data['data'][2]
        this.featuredCategories = this._route.snapshot.data['data'][3]
    }
    @ViewChild('widgetsQuantitySCroll', { read: ElementRef }) public widgetsQuantitySCroll: ElementRef<any>;
    @ViewChild('cardSCroll', { read: ElementRef }) public cardSCroll: ElementRef<any>;
    @ViewChild('franchiseLogoSCroll', { read: ElementRef }) public franchiseLogoSCroll: ElementRef<any>;
    @ViewChild('widgetsContent') widgetsContent: ElementRef;
    banners: HomeBanner[]
    bannerBG = "./assets/images/banner_bg.webp"
    configureBanners() {
        if (this.banners == null || this.banners.length == 0)
            return
        const carouselElement = document.getElementById('carousel-home');
        let bannerItems = []
        let indicatorItems = []
        this.banners?.forEach((banner, index) => {
            console.log(index);

            bannerItems.push({
                position: index,
                el: document.getElementById(`carousel-item-${index}`)
            })
            indicatorItems.push({
                position: index,
                el: document.getElementById(`carousel-indicator-${index}`)
            })
        })
        const options = {
            defaultPosition: 0,
            interval: 3000,
            indicators: {
                activeClasses: 'bg-[#5040dd] border-[#5040dd]',
                inactiveClasses: 'bg-white/50 hover:bg-[#a79dff] border border-[#a79dff]',
                items: indicatorItems
            },
            onNext: () => {
                // console.log('next slider item is shown');
            },
            onPrev: () => {
                // console.log('previous slider item is shown');
            },
            onChange: (a) => {
                //             "./assets/images/banner_bg.webp"
                // bannerBGBlackFriday = "./assets/images/banner_bg_black_friday.png"

                // this.bannerBG = a._activeItem.position == 1 ? './assets/images/banner_bg.webp' : './assets/images/banner_bg_black_friday.png'

                console.log('new slider item has been shown');
            },
        };

        initFlowbite();
        const carousel: CarouselInterface = new Carousel(carouselElement, bannerItems, options);
        carousel.cycle()
    }

    businessCategories = [

        {
            name: 'Real Estate',
            "id": 3,
            image: './assets/images/realEstate.svg'
        },
        {
            name: 'Education',
            "id": 17,
            image: './assets/images/education.svg'
        },
        {
            name: 'Beauty & Cosmetics',
            "id": 25,
            image: './assets/images/beautycare.svg'
        },
        {
            name: 'Restaurants',
            "id": 13,
            image: './assets/images/restaurants.svg'
        },
        {
            name: 'Sports & Fitness',
            "id": 22,
            image: './assets/images/fitness.svg'
        },
        {
            name: 'Hotel',
            "id": 15,
            image: './assets/images/hotel.svg'
        },
        {
            name: 'Insurance',
            "id": 5,
            image: './assets/images/insurance.svg'
        },
        {
            name: 'Health & beauty',
            "id": 38,
            image: './assets/images/health.svg'
        },
        {
            name: 'Day Care Services',
            "id": 27,
            image: './assets/images/daycare.svg'
        },
        {
            name: 'Business',
            "id": 24,
            image: './assets/images/business.png'
        },
        {
            name: 'Attorney',
            "id": 2,
            image: './assets/images/attorney.svg'
        },
        {
            name: 'Retail and Sales',
            "id": 33,
            image: './assets/images/retail_Sales.svg'
        },
        {
            name: 'Janitorial',
            "id": 35,
            image: './assets/images/janitorial.svg'
        },
        {
            name: 'Flooring',
            "id": 42,
            image: './assets/images/flooring.svg'
        },
        {
            name: 'Mortgage',
            "id": 4,
            image: './assets/images/mortgage.svg'
        },
        {
            name: 'Pharmacy',
            "id": 43,
            image: './assets/images/pharmacy.svg'
        }
    ]

    bannerCategories = [
        {
            link: ['/', 'category', 'business-cards', 'business-cards-standard-business-cards'],
            title: "Standard Business Cards",
            image: './assets/images/standard_businesscard.webp',
            name: "Best Standard Business Cards"
        },

        {
            link: ['/', 'category', 'signs', 'signs-standard-sign-board'],
            title: "Signs & Accessories",
            image: './assets/images/signs.webp',
            name: "Best sign and accessories"
        },

        {
            link: ['/', 'category', 'facebook'],
            title: "Facebook Posts",
            image: './assets/images/fb_posts.webp',
            name: "Facebook Posts"
        },

        {
            link: ['/', 'category', 'instagram'],
            title: "Instagram Posts",
            image: './assets/images/insta_posts.webp',
            name: "Instagram posts"
        }
    ]

    isSafariBrowser = false
    public scrollLeft(target): void {
        let element = null
        switch (target) {
            case 'vid': {
                element = this.cardSCroll.nativeElement
                break;
            }
            case 'business': {
                element = this.widgetsQuantitySCroll.nativeElement
                break;
            }
            case 'franchise': {
                element = this.franchiseLogoSCroll.nativeElement
                break;
            }
        }

        if (this.isSafariBrowser)
            element.scrollLeft -= 300
        else
            element.scrollTo({ left: (element.scrollLeft - 300), behavior: 'smooth' });
    }
    public scrollRight(target): void {
        let element = null
        switch (target) {
            case 'vid': {
                element = this.cardSCroll.nativeElement
                break;
            }
            case 'business': {
                element = this.widgetsQuantitySCroll.nativeElement
                break;
            }
            case 'franchise': {
                element = this.franchiseLogoSCroll.nativeElement
                break;
            }
        }
        if (this.isSafariBrowser)
            element.scrollLeft += 300
        else
            element.scrollTo({ left: (element.scrollLeft + 300), behavior: 'smooth' });
    }
    printingIcons = [
        {
            image: './assets/images/bookmarks.svg', name: "Bookmarks", categoryId: 'bookmarks'
        },
        {
            image: './assets/images/doorHangers.svg', name: "Door Hangers", categoryId: 'door-hangers'
        },
        {
            image: './assets/images/magnets.svg', name: "Magnets", categoryId: 'magnets'
        },
        {
            image: './assets/images/plastics.svg', name: "Plastics", categoryId: 'plastics'
        },
        {
            image: './assets/images/flyers.svg', name: "Flyers", categoryId: 'flyer'
        },
        {
            image: './assets/images/menu_cards.svg', name: "Menu Cards", categoryId: 'restaurant-menu'
        },
        {
            image: './assets/images/brochers.svg', name: "Brochures", categoryId: 'brochures'
        },
        {
            image: './assets/images/businesscards.svg', name: "Business Cards", categoryId: 'business-cards'
        },
        {
            image: './assets/images/car_magnets.svg', name: "Car Magnets", categoryId: 'car-magnets'
        },
        {
            image: './assets/images/greeting_cards.svg', name: "Greeting Cards", categoryId: 'greeting-cards'
        },
        {
            image: './assets/images/postcards.svg', name: "Postcards", categoryId: 'postcards'
        },
        {
            image: './assets/images/signs.svg', name: "Signs", categoryId: 'signs'
        },
    ];
    greetings = [
        {
            image: './assets/images/thanks_giving_card.webp',
            name: "Thanksgiving Cards",
            id: "greeting-cards-thanksgiving",
            altText: "Thanks giving cards"
        },
        {
            image: './assets/images/birthday.webp',
            name: "Birthday",
            id: "greeting-cards-birthday",
            altText: "Birthday greeting cards"
        },
        {
            image: './assets/images/christmas_card.webp',
            name: "Christmas Cards",
            id: "greeting-cards-christmas",
            altText: "christmas cards"
        },
        {
            image: './assets/images/halloween.webp',
            name: "Halloween",
            id: "greeting-cards-halloween",
            altText: "Halloween greeting cards"
        }
    ];

    franchiseIcons = ["./assets/images/franchise/Allstate.png", "./assets/images/franchise/LENDING.png",
        "./assets/images/franchise/Amelia-Underwriters.png", "./assets/images/franchise/LIBERTY-TAX.png",
        "./assets/images/franchise/American-National.png", "./assets/images/franchise/LISTO.png",
        "./assets/images/franchise/Baymont.png", "./assets/images/franchise/La-Quinta-Inn.png",
        "./assets/images/franchise/Berkshire.png", "./assets/images/franchise/Lightbridge-Academy.png",
        "./assets/images/franchise/Bricks-4-Kidz.png", "./assets/images/franchise/Marriott.png",
        "./assets/images/franchise/Burger-King.png", "./assets/images/franchise/Mathnasium-Learning-Centers.png",
        "./assets/images/franchise/Century21.png", "./assets/images/franchise/McDonald's.png",
        "./assets/images/franchise/Cinnabon.png", "./assets/images/franchise/Nationwide.png",
        "./assets/images/franchise/Cold-Stone-Creamery.png", "./assets/images/franchise/North-American.png",
        "./assets/images/franchise/Coldwell-Banker.png", "./assets/images/franchise/ONE-STOP.png",
        "./assets/images/franchise/Country-Inn.png", "./assets/images/franchise/PWA.png",
        "./assets/images/franchise/Days-Inn.png", "./assets/images/franchise/Papa-John's-Pizza.png",
        "./assets/images/franchise/Diary-Queen.png", "./assets/images/franchise/Pizza-Hut.png",
        "./assets/images/franchise/Discovery-Point.png", "./assets/images/franchise/Primrose-Schools.png",
        "./assets/images/franchise/Domino's.png", "./assets/images/franchise/Quality-Inn.png",
        "./assets/images/franchise/Estrella.png", "./assets/images/franchise/Sothebys.png",
        "./assets/images/franchise/Farmers-Insurance.png", "./assets/images/franchise/South-Florida-Montessori.png",
        "./assets/images/franchise/Federated-National.png", "./assets/images/franchise/State-Farm.png", "./assets/images/franchise/Fiesta-Auto.png",
        "./assets/images/franchise/Tax-Centers-of-America.png", "./assets/images/franchise/First-American.png",
        "./assets/images/franchise/Tayne-Law-GrouP.png", "./assets/images/franchise/Florida-Family-Insurance.png",
        "./assets/images/franchise/The-Franchise.png", "./assets/images/franchise/Genius-Kids.png",
        "./assets/images/franchise/The-Goddard-School.png", "./assets/images/franchise/Goosehead-Insurance.png",
        "./assets/images/franchise/Tutor-Doctos.png", "./assets/images/franchise/Great-Florida.png",
        "./assets/images/franchise/W-Hotels.png", "./assets/images/franchise/H&R.png",
        "./assets/images/franchise/We-Insure.png", "./assets/images/franchise/HAYATT.png",
        "./assets/images/franchise/We-The-People.png", "./assets/images/franchise/HOLIDAY-INN.png",
        "./assets/images/franchise/Weichert.png", "./assets/images/franchise/IWA.png",
        "./assets/images/franchise/Wingate.png", "./assets/images/franchise/Intercontinental.png",
        "./assets/images/franchise/exp.png", "./assets/images/franchise/Jackson-Hewitt.png",
        "./assets/images/franchise/motel6.png", "./assets/images/franchise/Keller-Williams.png",
        "./assets/images/franchise/ramada.png", "./assets/images/franchise/Kiddie-Academy.png",
        "./assets/images/franchise/remax.png", "./assets/images/franchise/Kids-R-Kids.png",
        "./assets/images/franchise/sheraton.png", "./assets/images/franchise/Kumon-Method-Learning.png",
        "./assets/images/franchise/sleep-inn.png"];
}

